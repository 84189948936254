<script>
    import AdminSidebar from "@views/admin/AdminSidebar";

    export default {
        components: {AdminSidebar },
        props:{
            hideMain:{
                type: Boolean,
                default: false
            }
        },

        created: function(){
        },
    }
</script>
<template>
    <div>
        <div class="row">
            <AdminSidebar></AdminSidebar>
            <div class="col-lg-9 col-xl-9">
                <slot name="admin-structure-beforecard"></slot>
                <div v-if="!hideMain" class="card">
                    <div class="card-body">
                        <slot name="admin-structure-content"></slot>
                    </div>
                </div>
                <slot name="admin-content-extraspace"></slot>
            </div>
        </div>

    </div>
</template>
