<script>

import adminProfileStructure from '@components/adminProfileStructure'
import moment from 'moment'
import {mapState} from "vuex";
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import PaginationRow from "@components/global/PaginationRow";

export default {
  page: {
      title: 'SuperAdmin'
  },
  components: {PaginationRow, adminProfileStructure},
  mixins: [PaginatedDataMixin],
  computed:{
    ...mapState({
      user: state => state.auth.currentUser
    }),
  },
  data() {
    return {
      fields: [
        {key: 'img', label: 'Εικονίδιο'},
        {key: 'title', label: 'Όνομα'},
        {key: 'content', label: 'Σημειώσεις'},
        {key: 'updatedAt', label: 'Τελευταία τροποίηση', formatter: value => {
            return moment(value).format('DD/MM/YYYY H:m')}
        }
      ],

      notification:{
          method: 'CREATE',
          title: null,
          content: null,
          icon: 'icon-bell',
          url: null
      }
    }
  },
  created: function(){
    this.api.url = "/admin/notifications";
    this.getData();
  },
  methods:{
    openEditNotificationModal(record){

        this.notification.method = 'UPDATE';
        this.notification.title = record.title;
        this.notification.icon = record.icon;
        this.notification.url = record.url;
        this.notification.content = record.content;

        this.$refs['add-notification'].show();
    },
    openAddNotificationModal(){
        this.notification = {method: 'CREATE', title: null, content: null, icon: 'icon-bell', url: null};
        this.$refs['add-notification'].show();
    },


    createNotification(){

        this.$axios.post(`/notifications`, this.notification).then(result =>{
            this.$notify({group: 'athlisis-notifications', type:'success', title: this.$t('success.title'), text: 'Η ειδοποίηση στάλθηκε επιτυχώς!'});
            this.$refs['add-notification'].hide();
            this.getData();
        }).catch(e=>{
            this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η αποστολή της ειδοποίησης απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
        })
    }
  }
}
</script>
<template>
    <adminProfileStructure :user="user">
        <template v-slot:admin-structure-content>

        <div class="card-title">
            <span class="float-right"><b-button variant="outline-primary" class="btn-rounded" @click="openAddNotificationModal"><i class="icon-plus"></i></b-button></span>
            Υπάρχοντες ενημερώσεις
        </div>
            <div v-if="api.rows.length === 0" class="error-ghost text-center">
                <img src="@assets/images/undraw-empty.svg" class="img-elements-not-found" alt="" height="250"/>
                <div class="text-center">
                    <h3 class="mt-4 text-uppercase font-weight-bold">ΔΕΝ ΥΠΑΡΧΟΥΝ ΕΝΗΜΕΡΩΣΕΙΣ</h3>
                </div>
            </div>

        <div v-if="api.rows.length > 0" class="table-responsive table-borderless table-striped mb-0">
            <b-table :items="api.rows" :fields="fields" class="table table-hover" responsive="sm" :current-page="api.page" @row-clicked="openEditNotificationModal">
                <template v-slot:cell(img)="row" class="text-center">
                    <i :class="row.item.icon" class="text-center font-17"></i>
                </template>
            </b-table>
        </div>

          <pagination-row :api="api" @fetchData="getData"></pagination-row>

        </template>
        <template v-slot:admin-content-extraspace>
            <b-modal id="add-notification" ref="add-notification" title="Προσθήκη ειδοποίησης" hide-footer body-class="p-3">
                <b-form>
                    <div class="row">
                        <div class="col-md-6">
                            <b-form-group id="title" label="Τίτλος ειδοποίησης" label-for="title" >
                                <b-form-input id="title" v-model="notification.title" type="text"></b-form-input>
                            </b-form-group>
                        </div>

                        <div class="col-md-6">

                            <b-form-group id="icon">
                                <label>
                                    <i :class='notification.icon'></i>  Εικονίδιο
                                </label>
                                <b-form-select v-model="notification.icon" required >
                                    <option value="icon-bell"> <i class="icon-bell"></i> Προεπιλεγμένο </option>
                                    <option value="icon-badge">Βραβείο</option>
                                    <option value="F">Θηλυκό</option>
                                </b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <b-form-group id="content" label="Κείμενο ειδοποίησης" label-for="content">
                        <b-form-textarea id="content" v-model="notification.content" type="textarea" placeholder="Εισάγετε κείμενο για την ειδοποίηση!" rows="6"></b-form-textarea>
                    </b-form-group>


                    <b-form-group v-if="notification.method === 'CREATE'" id="button-group" class="mt-2 text-right">
                        <b-button variant="primary" @click="createNotification"><i class="fe-plus"></i> Προσθήκη ειδοποίησης</b-button>
                    </b-form-group>
                    <b-form-group v-if="notification.method === 'UPDATE'" id="button-group" class="mt-2 text-right">
                        <b-button variant="primary" class="btn btn-success"><i class="fe-save"></i> Επεξεργασία ειδοποίησης</b-button>
                    </b-form-group>
                </b-form>
            </b-modal>
        </template>
    </adminProfileStructure>
    <!-- end row-->
</template>
