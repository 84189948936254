<script>
    /**
     * User Admin Sidebar Form component
     */
    export default {
        data() {
            return {
              menu: [
                {url: 'admin-home', icon: 'icon-power', text: 'Πίνακας ελέγχου'},
                {url: 'admin-users', icon: 'icon-people', text: 'Λίστα πελατών'},
                {url: 'admin-transactions', icon: 'icon-credit-card', text: 'Συναλλαγές'},
                {url: 'admin-coupons', icon: 'icon-present', text: 'Κουπόνια προσφοράς'},
                {url: 'admin-foods', icon: 'icon-drawar', text: 'Βάση δεδομένων Τροφίμων'},
                {url: 'admin-notifications', icon: 'icon-bell', text: 'Ειδοποιήσεις'},
                {url: 'admin-library', icon: 'icon-book-open', text: 'Βιβλιοθήκη'},
                {url: 'admin-user-reports', icon: 'icon-flag', text: 'Αναφορές χρηστών'},
              ]
            }
        },

        methods: {

            navigateTo(location){
                this.$router.push({name: location});
            }

        }
    }
</script>
<style lang="scss">
    .admin-navigation{
        padding:10px 15px;
        font-size:1.1em;
        color:#242424;
        cursor:pointer;
        border-radius:4px;
        i{
            padding-right:10px;
            padding-top:2px;
            font-size:1.1em;
        }
        &:hover{
            background-color:#d1d1d1;

        }
        &.active{
            background-color:#d4d4d4;
        }
    }
</style>
<template>
        <div class="col-lg-3 col-xl-3">
            <ul style="padding-left:0;list-style-type:none;">
              <li v-for="menuItem in menu" :key="menuItem.url">
                <div class="admin-navigation" :class="$route.name === menuItem.url ? 'active' : ''" @click="navigateTo(menuItem.url)"><i :class="menuItem.icon"></i> {{menuItem.text}}</div>
              </li>
            </ul>
        </div>
</template>
